/* 定义全局样式 */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* 定义容器样式 */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* 定义标题样式 */
h1 {
  text-align: center;
  font-size: 36px;
  color: #333;
}

/* 定义拖拽区域样式 */
.drop-area {
  width: 100%;
  height: 300px;
  border: 2px dashed gray;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 定义上传区域样式 */
.upload-area {
  text-align: center;
}

/* 定义进度区域样式 */
.progress-area {
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed gray;
  border-radius: 10px;
  animation: transform 0.5s ease-in-out; /* 当进度区域出现时，执行transform动画 */
}

/* 定义进度条样式 */
.progress-bar {
  width: 0%;
  height: 100%;
  background-color: green;
  border-radius: 10px;
}

/* 定义关闭按钮样式 */
.close-button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: -15px;
}

/* 定义错误信息样式 */
.error-message {
  color: red;
}

/* 添加一个新的类名，用于表示上传完成后的状态 */
.upload-done {
  animation: reverse 0.5s ease-in-out; /* 当上传完成后，执行reverse动画 */
}

/* 定义一个动画关键帧，用于实现矩形框和进度条之间的变换 */
@keyframes transform {
  from {
    height: 300px;
    border-radius: 10px;
  }
  to {
    height: 30px;
    border-radius: 0;
  }
}

/* 定义一个动画关键帧，用于实现进度条和矩形框之间的变换 */
@keyframes reverse {
  from {
    height: 30px;
    border-radius: 0;
  }
  to {
    height: 300px;
    border-radius: 10px;
  }
}

/* 定义一个动画关键帧，用于实现进度条的颜色变化 */
@keyframes color {
  from {
    background-color: green;
  }
  to {
    background-color: blue;
  }
}
